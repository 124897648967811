import { combineReducers } from "redux";
import storage from "redux-persist/lib/storage";
import localforage from 'localforage';
import storageSession from 'redux-persist/lib/storage/session'
import { persistReducer } from 'redux-persist'
// slices
import mailReducer from "./slices/old/mail";
import chatReducer from "./slices/old/chat";
import productReducer from "./slices/old/product";
import calendarReducer from "./slices/old/calendar";
import kanbanReducer from "./slices/old/kanban";
import accountSlice from "./slices/account";
import enquirySlice from "./slices/enquiry";
import institutionSlice from "./slices/institution";
import profileSlice from "./slices/profile";
import productSlice from "./slices/products";
import fileUploadSlice from "./slices/fileUpload";
import navigationSlice from "./slices/navigation";
import versionTrackingSlice from "./slices/versionTracking";
import tagSlice from "./slices/tags";
import goalSlice from "./slices/goals";
import locationSlice from "./slices/location";
import eventSlice from "./slices/events";
import groupSlice from "./slices/groups";
import locationGroupSlice from "./slices/locationGroups";
import clientSlice from "./slices/clients";
import contractTemplatesSlice from "./slices/contractTemplates";
import contractsSlice from "./slices/contract";
import emailsSlice from "./slices/email";
import emailTemplatesSlice from "./slices/emailTemplates";
import reportTemplatesSlice from "./slices/reportTemplate";
import reportSlice from "./slices/report";
import commentsSlice from "./slices/comments";
import notificationsSlice from "./slices/notifications";
import itemsSlice from "./slices/items";
import salesOrderSlice from "./slices/salesOrder";
import formSlice from "./slices/form";
import multiFormSlice from "./slices/multipartForm";
import userSlice from "./slices/users";
import invoiceSlice from "./slices/invoice";
import vendorSlice from "./slices/vendor";
import vendorItemSlice from "./slices/vendorItems";
import vendorWorkSlice from "./slices/vendorWorks";
import workorderItemSlice from "./slices/workorderItem";
import vendorInvoicesSlice from "./slices/vendorInvoices";
import calendarEventsSlice from "./slices/calendarEvents";
import holidaysSlice from "./slices/holidays";
import campaignSlice from "./slices/campaigns";
import campaignEmailTempSlice from "./slices/campaignEmailTemp";
import emailBurstSlice from "./slices/emailBurst";
import bookingSlice from "./slices/bookings";
import securityDepositSlice from "./slices/securityDeposit";
import nychaSlice from "./slices/nycha";
import donationSlice from "./slices/donations";
// ----------------------------------------------------------------------

const rootPersistConfig = {
  key: "resonanceMainApp",
  version: 1,
  blacklist: ['versionTracking'],
  storage: localforage,
};

const versionTrackingPersistConfig = { key: 'versionTracking', storage: storageSession }; // This is to session storage

const rootReducer = combineReducers({
  mail: mailReducer,
  chat: chatReducer,
  calendar: calendarReducer,
  kanban: kanbanReducer,
  // product: productReducer,
  product: productSlice.reducer,
  account: accountSlice.reducer,
  enquiry: enquirySlice.reducer,
  institution: institutionSlice.reducer,
  profile: profileSlice.reducer,
  fileUpload: fileUploadSlice.reducer,
  navigation: navigationSlice.reducer,
  versionTracking: persistReducer(versionTrackingPersistConfig, versionTrackingSlice.reducer),
  tags: tagSlice.reducer,
  goals: goalSlice.reducer,
  location: locationSlice.reducer,
  events: eventSlice.reducer,
  groups: groupSlice.reducer,
  locationGroup: locationGroupSlice.reducer,
  client: clientSlice.reducer,
  contractTemplates: contractTemplatesSlice.reducer,
  contracts: contractsSlice.reducer,
  emails: emailsSlice.reducer,
  emailTemplates: emailTemplatesSlice.reducer,
  reportTemplates: reportTemplatesSlice.reducer,
  reports: reportSlice.reducer,
  comments: commentsSlice.reducer,
  notifications: notificationsSlice.reducer,
  items: itemsSlice.reducer,
  salesOrder: salesOrderSlice.reducer,
  forms: formSlice.reducer,
  multiForms:multiFormSlice.reducer,
  users: userSlice.reducer,
  invoice: invoiceSlice.reducer,
  vendor: vendorSlice.reducer,
  vendorItems: vendorItemSlice.reducer,
  vendorWorks: vendorWorkSlice.reducer,
  workorderItems: workorderItemSlice.reducer,
  vendorInvoices: vendorInvoicesSlice.reducer,
  calendarEvents: calendarEventsSlice.reducer,
  holidays: holidaysSlice.reducer,
  campaign: campaignSlice.reducer,
  campaignEmailTemp: campaignEmailTempSlice.reducer,
  emailBurst: emailBurstSlice.reducer,
  bookings: bookingSlice.reducer,
  securityDeposit: securityDepositSlice.reducer,
  nycha: nychaSlice.reducer,
  donation: donationSlice.reducer
});

export { rootPersistConfig, rootReducer };

import { createSlice } from "@reduxjs/toolkit";
import { logoutUser } from "../actions/account";
import { getMultiformByModule, getMultiformByTagId, getMultiformSchemaByGoals, getMultiformSchemaByModule, getMultipartFormById } from "../actions/multipartForm";

const initialState = {
    error: false,
    isLoadingGetMultipartFormByTagIdAndIsEdit: false,
    multipartFormsForTag: [],
    isLoadingGetMultipartFormByModuleAndIsEdit: false,
    multipartFormsForModule: [],
    isLoadingGetMulipartFormsByGoalIds: false,
    isLoadingGetMulipartFormsByNonGoalIds: false,
    getMultiformSchemaByModuleschemaByGoalIds: [],
    schemaByNonGoalIds: [],
    singleMultipartFormData: {},
    formDetailsForGoals:[],
    formDetailsForOtherModules:[],
    isLoadingGetMultipartFormById: false
}

const multiFormSlice = createSlice({
    name: "multiForms",
    initialState,
    reducers: {
        "account/logoutUser": (state) => initialState,
    },
    extraReducers: {
        [getMultiformByTagId.pending]: (state, action) => {

            state.isLoadingGetMultipartFormByTagIdAndIsEdit = true;
            state.error = false
        },
        [getMultiformByTagId.fulfilled]: (state, action) => {
            state.isLoadingGetMultipartFormByTagIdAndIsEdit = false;
            state.multipartFormsForTag = action?.payload?.data?.multiForm
            state.error = false
        },
        [getMultiformByTagId.rejected]: (state, action) => {
            state.isLoadingGetMultipartFormByTagIdAndIsEdit = false;
            state.error = true
        },
        [getMultiformSchemaByGoals.pending]: (state, action) => {
            state.isLoadingGetMulipartFormsByGoalIds = true;
            state.error = false
        },
        [getMultiformSchemaByGoals.fulfilled]: (state, action) => {
            state.isLoadingGetMulipartFormsByGoalIds = false;
            state.formDetailsForGoals = action?.payload?.data
            state.error = false
        },
        [getMultiformSchemaByGoals.rejected]: (state, action) => {
            state.isLoadingGetMulipartFormsByGoalIds = false;
            state.error = true
        },

        [getMultiformSchemaByModule.pending]: (state, action) => {
            state.isLoadingGetMulipartFormsByNonGoalIds = true;
            state.error = false
        },
        [getMultiformSchemaByModule.fulfilled]: (state, action) => {
            state.isLoadingGetMulipartFormsByNonGoalIds = false;
            state.formDetailsForOtherModules = action?.payload?.data
            state.error = false
        },
        [getMultiformSchemaByModule.rejected]: (state, action) => {
            state.isLoadingGetMulipartFormsByNonGoalIds = false;
            state.error = true
        },
        [getMultiformByModule.pending]: (state, action) => {
            state.isLoadingGetMultipartFormByModuleAndIsEdit = true;
            state.error = false
        },
        [getMultiformByModule.fulfilled]: (state, action) => {
            state.isLoadingGetMultipartFormByModuleAndIsEdit = false;
            state.multipartFormsForModule = action?.payload?.data?.multiForm
            state.error = false
        },
        [getMultiformByModule.rejected]: (state, action) => {
            state.isLoadingGetMultipartFormByModuleAndIsEdit = false;
            state.error = true
        },
        [getMultipartFormById.pending]: (state, action) => {
            state.isLoadingGetMultipartFormById = true;
            state.error = false
        },
        [getMultipartFormById.fulfilled]: (state, action) => {
            state.isLoadingGetMultipartFormById = false;
            state.singleMultipartFormData = action?.payload?.multiForm
            state.error = false
        },
        [getMultipartFormById.rejected]: (state, action) => {
            state.isLoadingGetMultipartFormById = false;
            state.error = true
        },
        [logoutUser.fulfilled]: (state) => {
            return initialState;
        },
        [logoutUser.rejected]: (state) => {
            return initialState;
        },
    }
})
export const getError = (state) => {
    return state.multiForms.error

}
    ;
export const getIsLoadingGetMultipartFormByTagId = (state) => state.multiForms.isLoadingGetMultipartFormByTagIdAndIsEdit;
export const getIsLoadingGetMultipartFormByModule = (state) => state.multiForms.isLoadingGetMultipartFormByModuleAndIsEdit;
export const getMultipartFormsForTag = (state) => state.multiForms.multipartFormsForTag
export const getMultipartFormsForModule = (state) => state.multiForms.multipartFormsForModule
export const getIsLoadingGetMulipartFormsByGoalIds = (state) => state.multiForms.isLoadingGetMulipartFormsByGoalIds
export const getIsLoadingGetMulipartFormsByNonGoalIds = (state) => state.multiForms.isLoadingGetMulipartFormsByNonGoalIds
export const getIsLoadingGetMultipartFormById = (state) => state.multiForms.isLoadingGetMultipartFormById
export const getSingleMultipartForm = (state) => state.multiForms.singleMultipartFormData

export const getFormDetailsForGoals = (state) => state.multiForms.formDetailsForGoals
export const getFormDetailsForOtherModules = (state) => state.multiForms.formDetailsForOtherModules

export default multiFormSlice


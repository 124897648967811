import { createAsyncThunk } from "@reduxjs/toolkit";
import { checkStatus, composeAuth, AuthError } from "src/helpers/utils";
import { logoutUser } from "./account";
import querystring from "query-string";
import { keys } from "localforage";



export const getMultiformByTagId = createAsyncThunk(
    "multipartForms/getMultiformByTagId",
    async (payload, thunkApi) => {
        try {
            const url =
                `${process.env.REACT_APP_SERVER}/api/multiform/tagid/${payload.tagId}?edit=${payload.edit}`;

            const Authorization = composeAuth(payload?.jwt);
            const res = await fetch(url, {
                method: "GET",
                headers: {
                    "Content-type": "application/json",
                    Authorization,
                },
            });

            const response = await res.json();

            const validateData = checkStatus(response);
            return validateData;
        } catch (err) {
            if (err instanceof AuthError) {
                thunkApi.dispatch(logoutUser());
            }
            return thunkApi.rejectWithValue(err);
        }
    }
);

export const getMultiformByModule = createAsyncThunk(
    "multipartForms/getMultiformByModule",
    async (payload, thunkApi) => {
        try {

            const url =
                `${process.env.REACT_APP_SERVER}/api/multiform/moduleType/${payload.moduleType}?edit=${payload.edit}`;

            const Authorization = composeAuth(payload?.jwt);
            const res = await fetch(url, {
                method: "GET",
                headers: {
                    "Content-type": "application/json",
                    Authorization,
                },
            });

            const response = await res.json();

            const validateData = checkStatus(response);
            return validateData;
        } catch (err) {
            if (err instanceof AuthError) {
                thunkApi.dispatch(logoutUser());
            }
            return thunkApi.rejectWithValue(err);
        }
    }
);



export const getMultiformSchemaByGoals = createAsyncThunk(
    "multipartForms/getMultiformSchemaByGoals",
    async (payload, thunkApi) => {
        try {

            const url =
                `${process.env.REACT_APP_SERVER}/api/forms/keys?${querystring.stringify({
                    keys: payload.goalKeys
                })}`;


            const Authorization = composeAuth(payload?.jwt);
            const res = await fetch(url, {
                method: "GET",
                headers: {
                    "Content-type": "application/json",
                    Authorization,
                },
            });

            const response = await res.json();

            const validateData = checkStatus(response);
            return validateData;
        } catch (err) {
            if (err instanceof AuthError) {
                thunkApi.dispatch(logoutUser());
            }
            return thunkApi.rejectWithValue(err);
        }
    }
);

export const getMultiformSchemaByModule = createAsyncThunk(
    "multipartForms/getMultiformSchemaByModule",
    async (payload, thunkApi) => {
        try {
            // const url =
            // process.env.REACT_APP_SERVER + `/api/multiform/tagid/${payload.tagId}?`;
            const url =
                `${process.env.REACT_APP_SERVER}/api/forms/modules/keys?${querystring.stringify({
                    keys: payload.nonGoalFormKeys
                })}`;

            const Authorization = composeAuth(payload?.jwt);
            const res = await fetch(url, {
                method: "GET",
                headers: {
                    "Content-type": "application/json",
                    Authorization,
                },
            });

            const response = await res.json();

            const validateData = checkStatus(response);
            return validateData;
        } catch (err) {
            if (err instanceof AuthError) {
                thunkApi.dispatch(logoutUser());
            }
            return thunkApi.rejectWithValue(err);
        }
    }
);
export const getMultipartFormById = createAsyncThunk(
    "bookings/getMultipartFormById",
    async (payload, thunkApi) => {
        try {
            const Authorization = composeAuth(payload?.jwt);
            const url = process.env.REACT_APP_SERVER + `/api/multiform/id/${payload.id}`;
            const res = await fetch(url, {
                method: "GET",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization,
                },
            });

            const response = await res.json();
            const validateData = checkStatus(response);
            return validateData?.data;
        } catch (err) {
            if (err instanceof AuthError) {
                thunkApi.dispatch(logoutUser());
            }
            return thunkApi.rejectWithValue(err);
        }
    }
);